import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import SEO from '../components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="About" description="Essays that explore the deep history of the only inhabitable world we know." pageCanonical={'https://storybook.earth/about'} mdxType="SEO" />
    <div className="content-padding">
    
    <p className="banner-text">Stories from Planet Earth explores how we humans are transforming our planet with incredible speed—and what that might mean for us.</p>
    
    <h3>About</h3>
    
    <p>
        My name is <a href="http://www.mikemcdearmon.com" target="blank">Mike McDearmon</a> and this is my collection of essays about Earth. My background as a designer and researcher is studying the intersection of people, products, and their surroundings. After years of observing changes in my own surroundings—the weather, the seasons, and the natural environment giving way to the built environment—I couldn't help but feel that I was living in an age of rapid change unlike any other. That feeling raised a lot of questions, so I started looking for answers and now write as a way of sharing what I learn with others.
    </p> 
    
    <p>
        Thank you for reading. I hope these essays spark a sense of wonder and curiosity for the incredible planet we call home 🌏
    </p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      